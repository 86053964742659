import { Injectable } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  modal: NgxSmartModalComponent;

  callbackFn: () => void;

  constructor(private ngxSmartModalService: NgxSmartModalService) {
  }

  openModal(data: any, callback?: () => void) {
    if (!this.modal) {
      this.modal = this.ngxSmartModalService.get('basePopup');
      this.modal.onCloseFinished.subscribe(() => {
        this.ngxSmartModalService.resetModalData('basePopup');
        this.callback();
      });
    }
    if (callback) {
      this.callbackFn = callback;
    }
    this.ngxSmartModalService.resetModalData('basePopup');
    this.ngxSmartModalService.setModalData(data, 'basePopup');

    this.modal.open();
  }

  callback() {
    if (this.callbackFn) {
      this.callbackFn();
      this.callbackFn = null;
    }
  }
}
